import Vue from "vue";
import Router from "vue-router";
import appConfig from "../../src/app.config";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "Home",
      path: "/",
      component: () => import("./views/home"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("./views/login"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      name: "Logging in",
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      name: "Sair",
      path: "/logout",
      redirect: "/login",
    },
    {
      name: "Cadastre-se",
      path: "/register",
      component: () => import("./views/register"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      name: "Cadastre-se › Patrocinador",
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      name: "Esqueceu sua senha?",
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      name: "Alterar senha",
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/email",
      component: () => import("./views/email"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/email/token/:token",
      component: () => import("./views/email-token"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      name: "Minha Conta",
      path: "/account",
      component: () => import("./views/account/account"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Minha Senha",
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Meu PIX",
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Rede › Indicados",
      path: "/network/sponsored",
      component: () => import("./views/network/sponsored"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Rede › Equipe",
      path: "/network/team",
      component: () => import("./views/network/team"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Relatórios › Indicação",
      path: "/reports/indicacao",
      component: () => import("./views/reports/indicacao"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Downloads",
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },

    {
      name: "Station 10",
      path: "/station10",
      component: () => import("./views/station10/view"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Station 10 › Regras",
      path: "/station10/rules",
      component: () => import("./views/station10/rules"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Station 10 › Tabuleiro",
      path: "/station10/:id",
      component: () => import("./views/station10/position"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },

    {
      name: "Estação 100",
      path: "/station100",
      component: () => import("./views/station100/view"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Station 100 › Regras",
      path: "/station100/rules",
      component: () => import("./views/station100/rules"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Estação 100 › Tabuleiro",
      path: "/station100/:id",
      component: () => import("./views/station100/position"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },

    {
      name: "Estação 1000",
      path: "/station1000",
      component: () => import("./views/station1000/view"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Station 1000 › Regras",
      path: "/station1000/rules",
      component: () => import("./views/station1000/rules"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Estação 1000 › Tabuleiro",
      path: "/station1000/:id",
      component: () => import("./views/station1000/position"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },

    {
      name: "Station Perfect",
      path: "/stationperfect",
      component: () => import("./views/stationperfect/view"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Station Perfect › Regras",
      path: "/stationperfect/rules",
      component: () => import("./views/stationperfect/rules"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Station Perfect › Tabuleiro",
      path: "/stationperfect/:id",
      component: () => import("./views/stationperfect/position"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },

    {
      name: "Doações › Recebidas",
      path: "/donations/received",
      component: () => import("./views/donations/received"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "FAQ",
      path: "/faq",
      component: () => import("./views/faq"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Support",
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Support › View",
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "404",
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "*",
      redirect: "/register*",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          if (
            response.data.order &&
            response.data.order.id &&
            to.name != "Loja › Pedido"
          ) {
            return next("/store/order/" + response.data.order.id);
          }
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  }

  document.title = to.meta.title;
  next();
});

export default router;
